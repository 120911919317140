import App from "app/App";
import { AnalyticsUtil } from "app/util/AnalyticsUtil";
import MontaPosthogProvider from "providers/MontaPosthogProvider.tsx";
import MontaSentryErrorBoundaryProvider from "providers/MontaSentryErrorBoundaryProvider.tsx";
import React from "react";
import ReactDOM from "react-dom/client";
import "app/i18n";
import { BrowserRouter } from "react-router-dom";

AnalyticsUtil.init();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <MontaPosthogProvider>
            <BrowserRouter>
                <MontaSentryErrorBoundaryProvider>
                    <App />
                </MontaSentryErrorBoundaryProvider>
            </BrowserRouter>
        </MontaPosthogProvider>
    </React.StrictMode>
);
