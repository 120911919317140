import Config from "app/features/common/util/Config.ts";
import { PostHogProvider } from "posthog-js/react";
import React from "react";

/**
 * A wrapper around the PostHogProvider that only renders the provider if the PostHog key is set.
 * @param children
 * @constructor
 */
function MontaPosthogProvider({ children }: { children: React.ReactNode }) {
    if (import.meta.env.DEV) {
        return children;
    }

    if (!Config.posthogKey) {
        return children;
    }

    return (
        <PostHogProvider
            apiKey={Config.posthogKey}
            options={{
                api_host: "https://analytics.monta.app",
                respect_dnt: false,
                __add_tracing_headers: true,
            }}
        >
            {children}
        </PostHogProvider>
    );
}

export default MontaPosthogProvider;
