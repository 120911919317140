export default class Config {
    static stage?: string = import.meta.env.VITE_APP_STAGE;
    static apiBaseUrl?: string = import.meta.env.VITE_APP_API_URL;
    static loginBaseUrl?: string = import.meta.env.VITE_APP_LOGIN_URL;

    static sentryUrl?: string = import.meta.env.VITE_APP_SENTRY_URL;
    static posthogKey?: string = import.meta.env.VITE_APP_POSTHOG_KEY;

    static mixPanelKey?: string = import.meta.env.VITE_APP_MIXPANEL_KEY;
    static grafanaUrl?: string = import.meta.env.VITE_APP_GRAFANA_URL;

    static debugServiceToken?: string = import.meta.env.VITE_APP_SERVICE_TOKEN;
    static debugIdentityId?: string = import.meta.env.VITE_APP_IDENTITY_ID;
}
