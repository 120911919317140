import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { useSettingsContext } from "lib/settings/hooks/useSettingsContext.ts";
import PowerDisplayMode from "lib/theme/PowerDisplayMode.ts";
import React from "react";
import { useTranslation } from "react-i18next";

function SettingsPowerDisplayModeSelect() {
    const { t } = useTranslation();
    const settingsContext = useSettingsContext();

    const updatePowerDisplayMode = (powerDisplayMode: PowerDisplayMode) => {
        settingsContext.onUpdateField("powerDisplayMode", powerDisplayMode);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <FormControl fullWidth>
                <InputLabel id="power-display-mode-label">{t("settings.power_display_mode_label")}</InputLabel>
                <Select
                    labelId="power-display-mode-label"
                    id="power-display-mode"
                    value={settingsContext.powerDisplayMode}
                    label={"Power Display Mode"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                        updatePowerDisplayMode(event.target.value as PowerDisplayMode);
                    }}
                >
                    <MenuItem value={PowerDisplayMode.Kw}>{t("kW")}</MenuItem>
                    <MenuItem value={PowerDisplayMode.Amps}>{t("units.amps")}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default SettingsPowerDisplayModeSelect;
