import { SettingsContext } from "lib/settings/provider/SettingsContext";
import { useContext, useMemo } from "react";

export function useSettingsContext() {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error("useSettingsContext must be use inside SettingsProvider");
    }
    return context;
}

export function usePowerMeasurements() {
    const context = useSettingsContext();

    const shouldUseAmps = useMemo(() => {
        return context.powerDisplayMode === "amps";
    }, [context.powerDisplayMode]);

    function renderPowerOrCurrent(voltage?: number, amps?: number): number {
        const coalescedAmps = amps ?? 0;
        const coalescedVoltage = voltage ?? 0;

        if (shouldUseAmps) {
            return coalescedAmps;
        } else {
            return (coalescedVoltage * coalescedAmps) / 1000;
        }
    }

    const unit = useMemo(() => {
        if (shouldUseAmps) {
            return "Amps";
        } else {
            return "Kilowatts";
        }
    }, [shouldUseAmps]);

    const unitLong = useMemo(() => {
        if (shouldUseAmps) {
            return "A";
        } else {
            return "kW";
        }
    }, [shouldUseAmps]);

    return {
        shouldUseAmps,
        renderPowerOrCurrent,
        unit,
        unitLong,
    };
}
