import * as Sentry from "@sentry/react";
import ErrorPage from "app/pages/ErrorPage.tsx";
import React, { PropsWithChildren } from "react";
import "app/i18n.ts";

/**
 * A wrapper around the SentryErrorBoundary class, which will render our custom error page when an error is caught.
 * @param children
 * @constructor
 */
function MontaSentryErrorBoundaryProvider({ children }: PropsWithChildren) {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack }) => {
                const err = error as Error;
                Sentry.setContext("Component Stack", { componentStack });
                Sentry.setTag("errorType", err.name);
                Sentry.setTag("errorMessage", err.message);

                Sentry.captureException(err, {
                    contexts: {
                        componentStack: { value: componentStack },
                    },
                });

                return <ErrorPage type="500" error={err} />;
            }}
            showDialog
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}

export default MontaSentryErrorBoundaryProvider;
