import * as Sentry from "@sentry/react";
import Config from "app/features/common/util/Config";
import { LogMode } from "app/features/log/component/LogMode";
import { ModalType } from "app/hooks/useModal";
import UserDTO from "lib/user/model/UserDTO";
import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";

export class AnalyticsUtil {
    static init() {
        if (import.meta.env.DEV) {
            console.log("Skipping analytics setup in development mode");
            return;
        }
        // Sentry Setup
        if (Config.sentryUrl) {
            Sentry.init({
                dsn: Config.sentryUrl,
                tracesSampleRate: 0.0,
                environment: Config.stage,
                integrations: [
                    Sentry.httpClientIntegration(),
                    Sentry.browserTracingIntegration(),
                    Sentry.captureConsoleIntegration(),
                    Sentry.extraErrorDataIntegration(),
                    Sentry.dedupeIntegration(),
                ],
                tracePropagationTargets: ["monta.app"],
                sendDefaultPii: true,
            });
        }
        // Mix Panel Setup
        if (Config.mixPanelKey) {
            mixpanel.init(Config.mixPanelKey, {
                api_host: "https://api-eu.mixpanel.com",
                ignore_dnt: true,
                track_pageview: "url-with-path",
            });
        }
    }

    static setUser(user?: UserDTO) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.identify(user?.email);
            mixpanel.people.set({
                $name: user?.firstName + " " + user?.lastName,
                $email: user?.email,
            });
        }
        if (AnalyticsUtil.isSentryEnabled()) {
            Sentry.setUser({
                id: user?.id ? String(user?.id) : undefined,
                email: user?.email,
            });
        }
        if (AnalyticsUtil.isPosthogEnabled()) {
            if (user) {
                posthog.identify(user.id.toString(), {
                    identifier: user.identifier,
                    email: user.email,
                    name: user.firstName + " " + user.lastName,
                    language: user.language,
                });
            } else {
                posthog.reset();
            }
        }
    }

    static commandStart(commandName: string) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Command Started", { commandName: commandName });
        }
    }

    static commandEnd(commandName: string, successful: boolean, reason?: string) {
        const eventProperties = {
            commandName: commandName,
            successful: successful,
            reason: reason,
        };

        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Command Finished", eventProperties);
        }
    }

    static buttonClicked(buttonName: string, state?: string) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Button Clicked", { buttonName: buttonName, state: state });
        }
    }

    static modalShown(modalType: ModalType) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Modal Shown", { modalType: modalType });
        }
    }

    static settingChanged(settingName: string, settingValue: string) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Setting Changed", { settingName: settingName, settingValue: settingValue });
        }
    }

    static logModeChanged(logMode: LogMode) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Log Mode Changed", { logMode: logMode });
        }
    }

    static logCommandSubmit(
        coomandType: string,
        error: any,
        charge_point_error: any,
        charge_point_status: any,
        charge_point_id: string | undefined,
        brand_identifier: string | undefined,
        success: boolean | undefined | string
    ) {
        if (AnalyticsUtil.isMixPanelEnabled()) {
            mixpanel.track("Command Submit", {
                commandType: coomandType,
                error: error,
                charge_point_error: charge_point_error,
                charge_point_status: charge_point_status,
                charge_point_id: charge_point_id,
                brand_identifier: brand_identifier,
                success: success,
            });
        }
    }

    private static isSentryEnabled(): boolean {
        return Boolean(Config.sentryUrl);
    }

    private static isMixPanelEnabled(): boolean {
        return Boolean(Config.mixPanelKey);
    }

    private static isPosthogEnabled(): boolean {
        return Boolean(Config.posthogKey);
    }
}
