import { ArrowBack, Close, EvStation, Lan } from "@mui/icons-material";
import { Box, Card, CardActionArea, CircularProgress, CssBaseline, IconButton, Stack, Typography } from "@mui/material";
import { DashboardLayout, Navigation, NotificationsProvider } from "@toolpad/core";
import { ReactRouterAppProvider } from "@toolpad/core/react-router";
import { ModalType } from "app/hooks/useModal.ts";
import useShowModal from "app/hooks/useShowModal.ts";
import { setDayjsLocale } from "lib/config/dayjs.ts";
import HttpErrorBoundary from "lib/layout/HttpErrorBoundary.tsx";
import SettingsModal from "lib/settings/components/SettingsModal.tsx";
import SettingsUserAvatar from "lib/settings/components/SettingsUserAvatar.tsx";
import { useSettingsContext } from "lib/settings/hooks/useSettingsContext.ts";
import MontaThemeSwitchButton from "lib/theme/components/MontaThemeSwitchButton.tsx";
import getMontaTheme from "lib/theme/theme.ts";
import useIsUserAdmin from "lib/user/hooks/useIsUserAdmin.ts";
import React, { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useSearchParams } from "react-router-dom";

function getNavigation(): Navigation {
    const { t } = useTranslation();
    const isUserAdmin = useIsUserAdmin();

    return useMemo(() => {
        const navigation: Navigation = [
            {
                segment: "charge-points",
                title: t("charge_point.charge_points"),
                icon: (
                    <Box
                        sx={{ width: "100%", height: "100%" }}
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        <EvStation />
                    </Box>
                ),
            },
        ];
        if (isUserAdmin) {
            navigation.push(
                {
                    kind: "header",
                    title: "Admin",
                },
                {
                    segment: "load-balancing/load-balancing-groups",
                    title: t("load_balancing_group.page_title"),
                    icon: (
                        <Box
                            sx={{ width: "100%", height: "100%" }}
                            onClick={() => {
                                window.location.href = "/load-balancing/load-balancing-groups";
                            }}
                        >
                            <Lan />
                        </Box>
                    ),
                }
            );
        }
        return navigation;
    }, [isUserAdmin]);
}

function MontaLogo() {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", ml: 2, mr: 2 }} id="monta-logo">
            <img src="/logo.svg" height={25} width="100%" alt="Monta logo" />
        </Box>
    );
}

function CustomAppTitle() {
    return (
        <Box
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            onClick={() => (window.location.href = "/")}
        >
            <MontaLogo />
            <Typography variant="h4" color={"primary"}>
                CPI Tool
            </Typography>
        </Box>
    );
}

function AppToolbar() {
    const { showModal } = useShowModal();
    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <MontaThemeSwitchButton />
                <SettingsUserAvatar
                    onClick={() => {
                        showModal(ModalType.Settings);
                    }}
                />
            </Stack>
            <SettingsModal />
        </>
    );
}

function BackToHubBanner() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    let redirectUrl = searchParams.get("redirect_url");

    // If found in the URL, store in session storage; otherwise, retrieve it.
    if (redirectUrl) {
        sessionStorage.setItem("redirect_url", redirectUrl);
    } else {
        redirectUrl = sessionStorage.getItem("redirect_url");
    }

    if (!redirectUrl) {
        return null;
    }

    const handleDismiss = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        sessionStorage.removeItem("redirect_url");
        setSearchParams((prev) => {
            prev.delete("redirect_url");
            return prev;
        });
    };

    return (
        <Box sx={{ pt: 2, px: 2 }}>
            <Card>
                <CardActionArea component={Link} sx={{ display: "flex", alignItems: "center", p: 2 }} to={redirectUrl}>
                    <IconButton size="small" sx={{ mr: 2 }}>
                        <ArrowBack fontSize="inherit" />
                    </IconButton>
                    <img src="/hub_logo.svg" alt="Hub Logo" />
                    <Typography variant="button" sx={{ ml: 2, flexGrow: 1 }}>
                        {t("return_to_hub_button")}
                    </Typography>
                    <IconButton onClick={handleDismiss} size="small" sx={{ ml: 1 }}>
                        <Close fontSize="inherit" />
                    </IconButton>
                </CardActionArea>
            </Card>
        </Box>
    );
}

function MontaAppLayout() {
    const navigation = getNavigation();
    const { language } = useSettingsContext();
    setDayjsLocale(language ?? "en");

    return (
        <ReactRouterAppProvider navigation={navigation} theme={getMontaTheme()}>
            <CssBaseline />
            <NotificationsProvider>
                <DashboardLayout
                    defaultSidebarCollapsed={true}
                    slots={{
                        toolbarActions: AppToolbar,
                        appTitle: CustomAppTitle,
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "100vw",
                            overflowX: "hidden",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <BackToHubBanner />
                        <Suspense fallback={<CircularProgress />}>
                            <HttpErrorBoundary>
                                <Outlet />
                            </HttpErrorBoundary>
                        </Suspense>
                    </Box>
                </DashboardLayout>
            </NotificationsProvider>
        </ReactRouterAppProvider>
    );
}

export default MontaAppLayout;
